<template>
  <div class="auth">
    <el-button type="warning"
              class="login"
              @click="isAuthenticated ? logoutFromSmc() : goToAzureLoginUrl()"
              :disabled="!getAzureAppId && !getAzureTenantId"
              v-loading="loading"
              v-html="isAuthenticated ? 'Logout' : 'Login with Azure'"
  />

  </div>
</template>

<script>
import { ref } from 'vue'
import { ElNotification } from 'element-plus';
import { mapActions, mapGetters } from 'vuex';

export default {
  setup() {
    const loading = ref(false);

    return { loading }
  },
  name: 'Login',
  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "getAzureTenantId",
      "getAzureAppId"
    ]),
    azureRedirectUrl() { return `${window.location.protocol}//${window.location.hostname}${process.env.NODE_ENV === 'development' ? ':3000' : ''}/auth/microsoft_graph_auth/callback`;
    },
    azureLoginUrl() {
      return `https://login.microsoftonline.com/${this.getAzureTenantId}/oauth2/v2.0/authorize` +
             `?client_id=${this.getAzureAppId}` +
             `&redirect_uri=${this.azureRedirectUrl}` +
             '&response_type=code' +
             '&response_mode=query' +
             '&scope=openid+profile+email+User.Read'
    },
  },
  mounted() {
    if (!!this.$route.query.token) {
      this.$store.dispatch('auth/authorize', this.$route.query.token);
      this.$router.push({ name: 'runs' });
      ElNotification.success({
        title: "Success",
        text: "You successfully login"
      });
    }
  },
  methods: {
    ...mapActions("auth", [
      "logout"
    ]),
    logoutFromSmc() {
      this.loading = true;
      this.logout()
      this.loading = false;
    },
    goToAzureLoginUrl() {
      this.loading = true;
      window.location.href = this.azureLoginUrl
    },
  }
};
</script>
<style>
.auth {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 11px;
  cursor: pointer;
}

.el-button .el-loading-spinner .circular {
  margin-top: 12px;
  width: 18px;
  height: 18px;
  stroke: var(--el-button-text-color);
}
</style>
