import router from '@/router';
import { jwtDecode } from 'jwt-decode'

const MS_PER_SECOND = 1000;

export default {
  namespaced: true,
  state: {
    user: {},
    errors: null,
    first_name: null,
    last_name: null,
    email: null,
    password: null,
    AUTH_HEADER_NAME: 'Authorization',
    AZURE_APPLICATION_ID: null,
    AZURE_TENANT_ID: null,
    token: null,
    logoutTimerId: null,
  },
  mutations: {
    logout(state) {
      state.first_name = null;
      state.last_name = null;
      state.user = {};
      state.email = null;
      state.password = null;
      state.errors = null;
      state.token = null;
    },
    authenticate(state, value) { state.token = value; },
    setEmail(state, value) { state.email = value; },
    setFirstName(state, value) { state.first_name = value; },
    setLastName(state, value) { state.last_name = value; },
    setUser(state, value) { state.user = value; },
    setPassword(state, value) { state.password = value; },
    setAzureApplicationId(state, value) { state.AZURE_APPLICATION_ID = value },
    setAzureTenantId(state, value) { state.AZURE_TENANT_ID = value },
    setLogoutTimerId(state, value) { state.logoutTimerId = value },
  },
  getters: {
    getUser(state) { return state.user; },
    getEmail(state) { return state.email; },
    getFirstName(state) { return state.first_name; },
    getLastName(state) { return state.last_name; },
    getPassword(state) { return state.password; },
    isAuthenticated(state) { return !!state.token; },
    getAuthToken(state) { return `Barrier ${state.token}`; },
    getAzureAppId(state) { return state.AZURE_APPLICATION_ID; },
    getAzureTenantId(state) { return state.AZURE_TENANT_ID; },
    getAuthHeaderName(state) { return state.AUTH_HEADER_NAME; },
    getLogoutTimerId(state) { return state.logoutTimerId; },
  },
  actions: {
    logout({state, commit}) {
      localStorage.removeItem(state.AUTH_HEADER_NAME);
      commit('logout');
      router.push({ name: 'home' });
    },
    setEnv({commit}, env) {
      commit('setAzureApplicationId', env.AZURE_APPLICATION_ID);
      commit('setAzureTenantId', env.AZURE_TENANT_ID);
    },
    authorize({commit, dispatch, state}, token) {
      dispatch('setAuthToken', token)
      router.push({ name: 'runs' });
    },
    setAuthToken({commit, dispatch, state}, token) {
      localStorage.setItem(state.AUTH_HEADER_NAME, token);
      commit('setUser', JSON.parse(atob(token.split('.')[1])));
      commit('authenticate', token);
      dispatch('resetLogoutTimer');
    },
    resetLogoutTimer({commit, state, dispatch}) {
      if (state.logoutTimerId) {
        clearTimeout(state.logoutTimerId);
        commit('setLogoutTimerId', null);
      }
      const token = state.token;
      const decoded = jwtDecode(token);
      const jwtExpirationSeconds = decoded.exp;
      const jwtExpirationMS = jwtExpirationSeconds * MS_PER_SECOND;
      const currentTimeMS = Date.now();
      const delayMS = (jwtExpirationMS - currentTimeMS);
      if(delayMS < 0) {
        throw "jwtExpiration is in the past";
      }

      const logoutTimerId = setTimeout(() => {
        dispatch('logout');
      }, delayMS);
      commit('setLogoutTimerId', logoutTimerId);
    }
  },
};
