import axios from 'axios';
import store from '@/store';

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:3000';
}

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';

axios.interceptors.request.use(function (config) {
  if (store.getters['auth/isAuthenticated']) {
    config.headers[store.getters['auth/getAuthHeaderName']] = store.getters['auth/getAuthToken'];
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  if (response.headers.refresh_token) {
    store.dispatch('auth/setAuthToken', response.headers.refresh_token);
  }
  return response;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

const handleError = (error) => {
  if (error?.response?.status == 401) {
    store.dispatch('auth/logout');
  }
  throw new Error(`[RWV] ApiService ${error}`);
}

const ApiService = {
  query(resource, params) {
    return axios.get(resource, params)
                .catch(error => handleError(error));
  },

  get(resource, params) {
    return axios.get(resource, params)
                .catch(error => handleError(error));
  },

  post(resource, params) {
    return axios.post(resource, params)
                .catch(error => handleError(error));
  },

  update(resource, slug, params) {
    return axios.put(`${resource}/${slug}`, params)
                .catch(error => handleError(error));
  },

  put(resource, params) {
    return axios.put(resource, params)
                .catch(error => handleError(error));
  },

  delete(resource) {
    return axios.delete(resource)
                .catch(error => handleError(error));
  },
};

export default ApiService;
