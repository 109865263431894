<template>
  <div>
    <HeaderComponent />
    <el-main>
      <router-view />
    </el-main>
  </div>
</template>
<script>
import HeaderComponent from "@/components/HeaderComponent";
import ApiService from "@/common/api.service";
export default {
  name: 'App',
  components: {
    HeaderComponent,
  },
  mounted() {
    if (!this.$store.getters['auth/isAuthenticated'] &&
        localStorage.getItem(this.$store.getters['auth/getAuthHeaderName'])
    ) {
      this.$store.dispatch('auth/authorize', localStorage.getItem(this.$store.getters['auth/getAuthHeaderName']));
    }
    ApiService.get('/api/v1/auth/env')
      .then(response => {
        this.$store.dispatch('auth/setEnv', response.data);
      });
  }
}
</script>
<style>
body {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', Arial, sans-serif;
  background-color: rgba(20,112,176,.11);
  font-family: "Red Hat Text", sans-serif;
  margin: 0px;
}
:root {
  --bp-color: #358820;
  --k-color: #eb6a26;
  --k4-color: #2b63cc;
  --k2-color: #ff8126;
  --k3-color: #336ebd;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 10px;
}
</style>
