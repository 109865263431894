<template>
  <el-row class="shadow">
    <el-col :span="24">
      <el-card>
        <CreateRun></CreateRun>
        <el-table
          v-if="runs.length > 0"
          :data="runs"
          style="width: 100%"
          table-layout="auto"
        >
          <el-table-column class-name="id" prop="id" label="Id" />
          <el-table-column prop="folder" label="Folder" width="180" />
          <el-table-column prop="ini_file" label="Ini file" width="220" />
          <el-table-column prop="status" label="Status" width="100">
             <template #default="scope">
              <el-tag :type="tagType(scope.row.status)"
                      disable-transitions
                      v-html="scope.row.status"
              ></el-tag>
             </template>
          </el-table-column>
          <el-table-column prop="created_at" label="Date" width="160" />
          <el-table-column prop="user" label="User" width="200"></el-table-column>
          <el-table-column fixed="right" label="Reports" width="120">
            <template #default="scope">
              <el-button v-if="scope.row.status == 'completed'"
                         round size="small"
                         @click="getReport('download_smconverter_products', scope.row.id)"
              >Smconverter.zip</el-button>
              <br>
              <el-button v-if="scope.row.status == 'completed'"
                         round
                         size="small"
                         @click="getReport('download_expression_matrix', scope.row.id)"
              >GeneExpressionMatrix.json</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination small
                       v-if="runs.length > 0"
                       background
                       layout="prev, pager, next"
                       :total="total_runs"
                       @current-change="handleCurrentChange"
                       :page-size=15
                       class="mt-4"
        />
      </el-card>

    </el-col>
    <el-col :span="4">
    </el-col>
  </el-row>
  <p>Version 1.0.3</p>
</template>

<script type="text/javascript">
import CreateRun  from '@/components/CreateRun';
import ApiService from "@/common/api.service";
import { ElNotification } from 'element-plus';

export default {
  name: 'ProfileView',
  data() {
    return {
      default_fields: {},
    }
  },
  computed: {
    runs() { return this.$store.getters['runs/getRuns'] },
    total_runs() { return this.$store.getters['runs/getTotalRuns'] },
  },
  components: {
    CreateRun,
  },
  methods: {
    handleCurrentChange(page) {
      this.$store.dispatch('runs/getRuns', page)
    },
    tagType(status) {
      if (status == 'completed') {
        return 'success'
      } else if (status == 'failed') {
        return 'danger'
      } else {
        return ''
      }
    },
    getReport(type, run_id) {
      ApiService.get(
        `/api/v1/runs/${run_id}/${type}`,
        { responseType: 'blob' }
      ).then((response) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.setAttribute('download', response.headers['filename']);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        ElNotification.error({
          title: "File not found",
          text: "File not found"
        });
      })
    },
  },
  created() {
    this.$store.dispatch('runs/getRuns', 1)
  }
};
</script>

<style>
.el-table .id .cell {
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
