import ApiService from "@/common/api.service";

export default {
  namespaced: true,
  state: {
    runs: [],
    total_runs: 0,
  },
  mutations: {
    addToRuns(state, payload) { state.runs.unshift(payload) },
    setRuns(state, payload) { state.runs = payload },
    setTotalRuns(state, payload) { state.total_runs = payload },
    // setDisabledButton(state, payload) {
    //   state.runs.forEach((run) => {
    //     if (run.id === payload.id) {
    //       run.disabled = payload.disabled;
    //     }
    //   });
    // }
  },
  getters: {
    getRuns(state) { return state.runs },
    getTotalRuns(state) { return state.total_runs },
  },
  actions: {
    setDisabledButton({commit}, payload) {

    },
    getRuns({commit}, page) {
      ApiService.get('/api/v1/runs', { params: { page: page } })
        .then((response) => {
          commit('setRuns', response.data);
          commit('setTotalRuns', parseInt(response.headers['x-total-count']));
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
};
